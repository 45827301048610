(function () {
  const links = [...document.querySelectorAll(".nav-link")];
  const contentBlocks = [...document.querySelectorAll(".content-block")];

  function setClasses(activeLinkHash, displayedBlock) {
    links.forEach(link => link.classList.toggle("active", link.getAttribute("href") === activeLinkHash));
    contentBlocks.forEach(block => block.classList.toggle("displayed", block.id === displayedBlock));
  }

  function loadContent() {
    const hash = (window.location.hash || "").substring(1);

    switch (hash) {
      case "services":
        setClasses("#services", "services");
        break;
      case "contacts":
        setClasses("#contacts", "contacts");
        break;
      case "":
      default:
        setClasses("#", "main");
    }
  }

  window.onhashchange = loadContent;
  loadContent();

  const burgerBtn = document.querySelector("#header-navigation-button");
  const navigationBlock = document.querySelector(".navigation");

  const linkClickHandler = () => {
    console.log("changed")
    if (navigationBlock && navigationBlock.classList.contains("displayed")) {
      navigationBlock.classList.remove("displayed")
      document.body.style.overflow = null;
    }
    links.forEach(link => {
      link.removeEventListener('click', linkClickHandler)
    });
  }

  if (burgerBtn && navigationBlock) {
    burgerBtn.addEventListener("click", () => {
      if (navigationBlock.classList.contains("displayed")) {
        navigationBlock.classList.remove("displayed")
        document.body.style.overflow = null;
      } else {
        navigationBlock.classList.add("displayed")
        document.body.style.overflow = "hidden";
        links.forEach(link => {
          link.addEventListener('click', linkClickHandler)
        });
      }
    })
  }
})();
